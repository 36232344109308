
import AppListing from "@/resources/js/Abstract/AppListing";
import Lang from "@/resources/js/components/Lang";
import ArtistShort from "@/resources/views/artists/ArtistShort";

export default {
    components: {
        ArtistShort,
    },
    mixins: [
        AppListing,
        Lang,
    ],
    name: "Home",



}