import {getUrlWithoutLang} from '@/resources/js/components/Lang'

export default {
    data: function () {
        return {
            data: null,
            url: null,
        }
    },
    created: function created() {
        this.url = getUrlWithoutLang(this.$route.path);
        this.loadData();
    },

    methods: {
        loadData: function () {
            let options = {
                params: {
                    lang: this.currentLang.code,
                }
            };

            this.axios.get(this.url, options)
                .then(response => {
                    this.populateData(response.data);
            })
                .catch(error => {
                // TODO handle error
console.log(error);
            });
        },
        populateData: function populateData (object) {
            this.data = object;
        },
    },

}