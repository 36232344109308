import {getUrlWithoutLang} from '@/resources/js/components/Lang'

export default {
    data: function data() {
        return {
            orderBy: {
                column: 'id',
                direction: 'asc'
            },
            filters: {},
            search: '',
            collection: null,
            data: null,
            url: null,
            responseStatus: null,
        }
    },
    created: function created() {
        this.url = getUrlWithoutLang(this.$route.path);
        this.loadData();
    },

    methods: {
        loadData: function loadData() {
            let options = {
                params: {
                    orderBy: this.orderBy.column,
                    orderDirection: this.orderBy.direction,
                }
            };

            this.axios.get(this.url, options)
                .then(response => {
                    this.responseStatus = response.status;
                    this.populateData(response.data);
            })
                .catch(error => {
                // TODO handle error
                // this.responseStatus = error.response.status;
                // this.populateData(error.response);
console.log(error);
            });
        },
        populateData: function populateData (object) {
            this.collection = object.data;
            this.data = object.data;
        },
    },

}