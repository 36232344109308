import Lang from "@/resources/js/components/Lang";

export default {
    name: "ArtistShort",
    mixins: [Lang],

    data() {
        return {
            artist: {},
        }
    },
    watch: {
        '$root.$refs.player.streamMeta': function(meta) {
            this.artist = meta.artist;
            this.loadArtist(this.artist.alias);
        },
    },
    mounted() {
// console.log(this.$root.$refs.player.streamMeta)
        this.artist = this.$root.$refs.player.streamMeta.artist;
        this.loadArtist(this.artist.alias);
    },
    methods: {
        loadArtist(artistAlias) {
            this.axios.get(`/artists/${artistAlias}`, {params: {short: true, lang: this.currentLang.code}})
                .then(response => {
                    this.artist = response.data;
                }).catch(error => console.log(error));
        },
    },
}